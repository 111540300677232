import React from "react";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import {
  Navbar,
  TopBanner,
  Banner,
  Trusted,
  Intro,
  FeaturedIn,
  Process,
  Feedback,
  Achievement,
  Team,
  Services,
  Contact,
  RecognizedBy,
  Footer,
  FooterBanner,
} from "../components";

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

const Home = () => {
  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);
  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);
  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);
  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);
  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);
  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);
  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);
  const ref9 = useRef();
  const isVisible9 = useIsVisible(ref9);
  const ref10 = useRef();
  const isVisible10 = useIsVisible(ref10);
  const ref11 = useRef();
  const isVisible11 = useIsVisible(ref11);
  const ref12 = useRef();
  const isVisible12 = useIsVisible(ref12);
  const ref13 = useRef();
  const isVisible13 = useIsVisible(ref13);
  const ref14 = useRef();
  const isVisible14 = useIsVisible(ref14);

  return (
    <>
      <Helmet>
        <title>GoPool - Your Ultimate CabPooling and Ridesharing App</title>
      </Helmet>

      <div className="sticky top-0 z-[1000]">
        <Navbar />
      </div>
      <div className="">
        <TopBanner />
      </div>
      <div
        ref={ref2}
        className={`transition-opacity ease-in duration-700 ${
          isVisible2 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Banner />
      </div>

      <div
        ref={ref3}
        className={`transition-opacity ease-in duration-700 ${
          isVisible3 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Trusted />
      </div>

      <div
        ref={ref4}
        className={`transition-opacity ease-in duration-700 ${
          isVisible4 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Intro />
      </div>
      <div
        ref={ref5}
        className={`transition-opacity ease-in duration-700 ${
          isVisible5 ? "opacity-100" : "opacity-0"
        }`}
      >
        <FeaturedIn />
      </div>
      <div
        ref={ref6}
        className={`transition-opacity ease-in duration-700 ${
          isVisible6 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Process />
      </div>
      <div
        ref={ref10}
        className={`transition-opacity ease-in duration-700 ${
          isVisible10 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Services />
      </div>
      <div
        ref={ref7}
        className={`transition-opacity ease-in duration-700 ${
          isVisible7 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Feedback />
      </div>
      <div
        ref={ref8}
        className={`transition-opacity ease-in duration-700 ${
          isVisible8 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Achievement />
      </div>
      <div
        ref={ref9}
        className={`transition-opacity ease-in duration-700 ${
          isVisible9 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Team />
      </div>
      <div
        ref={ref11}
        className={`transition-opacity ease-in duration-700 ${
          isVisible11 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Contact />
      </div>
      <div
        ref={ref12}
        className={`transition-opacity ease-in duration-700 ${
          isVisible12 ? "opacity-100" : "opacity-0"
        }`}
      >
        <RecognizedBy />
      </div>
      <div
        ref={ref13}
        className={`transition-opacity ease-in duration-700 ${
          isVisible13 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Footer />
      </div>
      <div
        ref={ref14}
        className={`transition-opacity ease-in duration-700 ${
          isVisible14 ? "opacity-100" : "opacity-0"
        }`}
      >
        <FooterBanner />
      </div>
    </>
  );
};

export default Home;
