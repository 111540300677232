import React from "react";
import { useNavigate } from "react-router-dom";

const BlogsCard = ({ Blog }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/blogs/${Blog.slug}`)}
      className="bg-white cursor-pointer rounded-lg flex flex-col justify-between text-black h-[480px] mx-2"
    >
      <div className="m-4 cursor-pointer">
        <img
          src={Blog.thumbnail}
          className="w-full rounded-lg h-[250px]"
          alt=""
        />
        {/* <h3 className="text-primary py-2 font-bold mt-3">{Blog.topic}</h3> */}
        <h3 className="line-clamp-3 font-bold mt-3 text-xl text-black">
          {Blog.title}
        </h3>
        <h3 className="line-clamp-3 py-1 text-gray-400 mt-3">{Blog.content}</h3>
      </div>
    </div>
  );
};

export default BlogsCard;
