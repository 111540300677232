import React from "react";
import Slider from "react-slick";
import { gopoolServices } from "../constants";
import ServicesCard from "./ServicesCard";

const Services = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="w-full py-20 bg-[#ececec]">
      <div className="md:max-w-[1215px] m-auto max-w-[550px] px-4 gap-4 md:px-0">
        <div className="py-4">
          <p className="py-3 text-4xl font-heading font-medium">
            GoPool's <span className="text-[#5247FF]">Features</span>
          </p>
          <p className="text-[#6D737A]">
            Discover the features that make every ride secure, convenient, and connected.
          </p>
        </div>

        <Slider {...settings}>
          {gopoolServices.map((service, i) => (
            <ServicesCard service={service} key={i} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Services;
