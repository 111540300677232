import {
  register,
  findtravelers,
  akash,
  sunil,
  nitish,
  chetan,
  kush,
  fatima,
  moneycontrol,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  toi,
  curlyTales,
  yourStory,
  cnbc,
  moneyControlThumbnail,
  toiThumbnail,
  curlyTalesThumbnail,
  yourStoryThumbnail,
  feature1,
  feature2,
  feature3,
  feature4,
  blogImage1,
  blogImage2,
  blogThumbnail1,
  blogThumbnail2,
  blogFooterBanner1,
  blogCard1,
  blogCard2,
} from "../assets";

export const users = [
  {
    TotalUsers: "29,000",
    connections: "5,000",
    grv: "90,000",
    co2: "40",
  },
];
export const feedbacks = [
  {
    name: "Mohit Gupta",
    ans: `"Interface is intuitive and excellent. I was able to find the co-passanger at that time . Verified user and calling them in no time is great feature. 
    As this is a new app, would recommend to make app changes to ensure pre booking before boarding flight to Bangalore."`,
  },
  {
    name: "Sharik Ahmad",
    ans: `"I just wanted to say that I love your app GoPool The interface is so intuitive and easy to use. Recently I used this app and got the fellow passenger which reduced the fare to half amount. Overall, I think your app is great and I really appreciate the hard work that you and your team have put into it. Thank you for creating such a useful tool."`,
  },
  {
    name: "Swastik Roy",
    ans: `"I was lucky enough to stumble across “GoPool” ride sharing app. If you're staying far away from Bangalore Airport and you don't mind sharing a ride with someone of your vicinity and split the bills, this is the right platform for you. You can select your destination and connect with your potential co passenger hassle free."`,
  },
];

export const steps = [
  {
    istrue: true,
    num: "1",
    heading: "Register",
    image: register,
    guide: [
      "Register/Login via mobile number.",
      "Update your personal details.",
      "Add a nice profile picture.",
      "Update first name, last name, gender and age.",
    ],
  },
  {
    istrue: false,
    num: "2",
    heading: "Find travelers",
    image: findtravelers,
    guide: [
      "Add your destination.",
      "You will see number of people traveling to same location.",
      "Request for a call, discuss, meet, and ride together.",
      "Save money! Simple as that.",
    ],
  },
];
const generateSlug = (title) =>
  title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");

export const AllBlogs = [
  {
    id: 1,
    thumbnail: blogCard1,
    topic: "Gopool",
    title:
      "Traveling Alone at Night? GoPool Prioritizes Safety with Special Features for All",
    slug: generateSlug(
      "Traveling Alone at Night? GoPool Prioritizes Safety with Special Features for All"
    ),
    content:
      "How many of you travel at night? Or let me rephrase it. How many of you feel dreaded while traveling at night? Needless to say, 8 out of 10 women who are traveling at night, whether from the office or airport to home, feel unsafe.",
    date: "11 October 2024",
  },
  {
    id: 2,
    thumbnail: blogCard2,
    topic: "Gopool",
    title:
      "Urban Transportation and Climate Change in India: A Call for Sustainable Solution",
    slug: generateSlug(
      "Urban Transportation and Climate Change in India: A Call for Sustainable Solution"
    ),
    content:
      "Addressing climate change requires urban mobility, especially in nations with high population densities like India. The emergence of ride-sharing applications has brought about creative ways to lessen the environmental impact of conventional modes of transportation.",
    date: "11 October 2024",
  },
];

export const BlogContents = [
  {
    id: 1,
    slug: generateSlug(
      "Traveling Alone at Night? GoPool Prioritizes Safety with Special Features for All"
    ),
    metaTitle: "GoPool’s Night time Safety for Solo Travelers and Females",
    metaDescription:
      "Night travel on roads can feel risky and unsafe. But with GoPool, this is now all safe and secure. Read this guide to know the reason behind it",
    title:
      "Traveling Alone at Night? GoPool Prioritizes Safety with Special Features for All",
    img: akash,
    name: "Akash ",
    des: "Founder @GoPool",
    date: "Published on March 8, 2024",
    footerText: "Travel Safe, Travel Smart, Travel With GoPool.",
    footerBanner: blogFooterBanner1,
    intro: [
      "How many of you travel at night? Or let me rephrase it. How many of you feel dreaded while traveling at night? Needless to say, 8 out of 10 women who are traveling at night, whether from the office or airport to home, feel unsafe.",

      "Thats not it! The moment they book a cab and sit in it, the first thing they do is share the location with their family and friends.",

      "The story does not just end here. A single turn to an unknown road will make her shiver with fear. This is not new, and it cannot be denied. But the question is, what is the solution in such cases?",

      "GoPool has developed one of the finest solutions for such travelers. Whether women or men, their solution is perfect to ensure a safe and sound commute, free of hassle.",

      "Read this to know how.",
    ],
    div: [
      {
        heading: "Understanding the GoPool Concept",
        para: [
          "Before we explore how GoPool is helping improve your commutes, let us quickly understand the concept behind it. So, GoPool offers cabpooling services. Here, you need to follow simple steps, and you can find your travel buddy. ",

          "This cabpooling allows you to save money and get a company. As of now, this service is mainly available for travel to and from airports, but it will soon expand for office commutes, too. ",
        ],
        head: "The idea here is very simple.",
        points: [
          {
            type: "mixed",
            content: [
              { text: "Download the " },
              { link: "app", url: "https://GoPool.app/download" },
            ],
          },
          {
            type: "text",
            content: "Fill in your details as prompted on the screen.",
          },
          { type: "text", content: "Get your Aadhar verification completed." },
          { type: "text", content: "Add your LinkedIn details. Its optional." },
          { type: "text", content: "Your profile is now ready." },
          { type: "text", content: "Now, add your travel details." },
          { type: "text", content: "Look for travelers on the same route." },
          { type: "text", content: "Connect with them." },
          {
            type: "text",
            content: "Chat to see if you can find your travel buddy.",
          },
          { type: "text", content: "Once you find it, close the chat." },
        ],
        img: blogImage1,
        footer: [
          {
            type: "mixed",
            content: [
              {
                text: "Now, meet the person at the airport, or the location decided. Then book a cab and share it. Pay for your half, and you will be at your destination. And this time, ",
              },
              {
                link: "your cab fare will not burn a hole in your pocket!",
                url: "https://www.moneycontrol.com/technology/meet-the-startup-that-turns-airport-rides-into-cheap-ola-uber-carpools-article-12720323.html",
              },
            ],
          },
          {
            type: "text",
            content:
              "Now that you know the basics let us understand how this makes travel safer.",
          },
        ],
      },
      {
        heading: "How Night Travel Safety is Enhanced with GoPool?",
        para: [
          "GoPool focuses on the safety of every traveler. Here are a few ways through which it ensures safer and more comfortable rides: ",
        ],
        img: blogThumbnail1,
      },
      {
        heading: "1. Verified Profiles",
        para: [
          "Every person who downloads the app needs to complete the verification process. This means you are well aware of whom you will travel with. This process ensures a safer and smoother travel experience.",
        ],
      },
      {
        heading: "2. Community Feedback",
        para: [
          "GoPool encourages everyone to share their travel buddy's feedback. This community-driven accountability helps maintain high safety standards and improves service reliability",
        ],
      },
      {
        heading: "3. Buddy Selection Facility",
        para: [
          "This is a standout feature of the app. If you are a female traveler, you have a choice to select if you wish to travel with females only. This feature provides additional comfort and security, making it easier for women to feel safe while traveling at night. ",
        ],
      },
      {
        heading: "4. Customizable Travel Decisions",
        para: [
          "GoPool allows you to make your own travel decisions. Once you have found a travel buddy, it is up to you both to decide whether you want to add another person or not. Additionally, the choice of cab service is also based on both of your decisions. This allows you to tailor your travel experience to your needs and preferences.",
        ],
      },
      {
        heading: "Trust Your Nighttime Commute to GoPool’s Safety Features",
        para: [
          "Traveling at night can be inevitable at times. This is where you need to be sure that the mode you are choosing is safe and secure in all ways. GoPool offers a higher degree of safety to travelers by following its selection and verification features. ",

          "This simple step facilitates better commutes, safer trips, and even budget-friendly expenses. And who knows, you build a social connection on your way, which can help you give wings to your dreams.",

          "So, next time you need to travel to and from the airport, visit GoPool and gain better support and travel experience!",
        ],
      },
    ],
  },
  {
    id: 2,
    slug: generateSlug(
      "Urban Transportation and Climate Change in India: A Call for Sustainable Solution"
    ),
    metaTitle: "Ridesharing for Sustainable Transport in India’s Cities",
    metaDescription:
      "Learn how ridesharing apps like GoPool support sustainable urban transport in India by reducing traffic, emissions, and travel costs for city commuters",
    title:
      "Urban Transportation and Climate Change in India: A Call for Sustainable Solution",
    img: akash,
    name: "Akash ",
    des: "Founder @GoPool",
    date: "Published on March 8, 2024",
    footerText: "Travel Safe, Travel Smart, Travel With GoPool.",
    footerBanner: blogFooterBanner1,
    publishedBy: "Shelja Rathi",
    intro: [
      "Addressing climate change requires urban mobility, especially in nations with high population densities like India. The emergence of ride-sharing applications has brought about creative ways to lessen the environmental impact of conventional modes of transportation. These apps' shared taxi services provide an effective and environmentally friendly substitute that dramatically lowers the carbon footprint of cities. In this blog, we’ll examine how India's urban transportation system affects climate change and emphasizes the advantages of using ride-sharing applications to address the issue.",

      "Read this to know how.",
    ],
    div: [
      {
        heading: "The Significance of Urban Transportation in India",
        para: [
          "The significance of urban transportation in India cannot be overstated. As one of the most densely populated countries in the world, India faces numerous challenges in terms of transportation infrastructure and its impact on climate change. With rapid urbanization and the increasing number of vehicles on the road, the carbon emissions from the transport sector have reached alarming levels.",

          "Urban transportation is essential to India's efforts to mitigate climate change. It acts as the economy's backbone, connecting people to education, employment, and other services. However, using traditional modes of transportation has significantly increased air pollution, greenhouse gas emissions, and overall environmental damage.",

          "To address these problems, we must shift our perspective on urban transportation. Embracing co-traveler discovery apps like GoPool that allows to share a cab, can provide a sustainable and efficient mode of transportation while significantly reducing carbon emissions.",
        ],
        head: "The idea here is very simple.",
        img: blogThumbnail2,
      },
      {
        heading: "The Significance of GoPool",
        para: [
          "GoPool is specifically built for airport visits and office commutes, solving a major traveler concern. The software allows users to connect with other passengers and split their travel expenses. This app offers convenience and cost savings while encouraging cabpooling and lowering the number of vehicles on the road.",

          "GoPool provides a much-needed alternative to cab services in areas such as Bengaluru, which have become increasingly pricey. Traditional bus choices frequently involve the trouble of organizing transportation to and from the bus stop, making them less appealing to many commuters. GoPool addresses this issue by providing a simple platform that links travelers, making it easy to discover co-passengers for shared journeys, whether you are headed to the airport or the office.",
        ],
        img: blogImage2,
      },
      {
        heading:
          "Role of Transportation Companies and Individuals in Reducing Emissions",
        para: [
          "While the government plays an important role in promoting sustainable urban mobility, transportation services and people may also help to reduce emissions and create a greener future.",

          "Transportation services, especially ride-sharing applications, are responsible for making environmentally beneficial choices. They can drastically reduce carbon emissions by adding electric or hybrid vehicles to their fleet. These businesses should also spend on R&D to discover new technology and sustainable practices.",

          "Individual commuters can help in minimizing carbon emissions. People can significantly reduce the number of vehicles on the road by cabpooling, using public transportation, walking, or cycling, resulting in less traffic congestion and lower carbon emissions.",
        ],
      },
      {
        heading: "Conclusion",
        para: [
          "With the continual growth of urban mobility, apps like GoPool are critical for campaigning for sustainable behaviours. GoPool helps people save money while also fostering a more ecologically friendly society by encouraging shared trips and cutting travel costs.",

          "To properly address climate change, governments, transportation firms, and creative projects such as GoPool must work together to develop a sustainable urban transportation system. Today, we encourage you to make a difference and be a change agent. Let us work together to create a more ecologically friendly future for India and set the standard for a sustainable world.",
        ],
      },
    ],
  },
];

export const features = [
  {
    thumbnail: moneyControlThumbnail,
    ans: "Meet the startup that turns airport rides into cheap Ola-Uber cabpools",
    site: "Money Control",
    logo: moneycontrol,
    link: "https://www.moneycontrol.com/technology/meet-the-startup-that-turns-airport-rides-into-cheap-ola-uber-carpools-article-12720323.html",
  },
  {
    thumbnail: toiThumbnail,
    ans: "This startup plans to turn Ola, Uber airport rides into budget-friendly cabpools, here's how - Times of India",
    site: "The Times of India",
    logo: toi,
    link: "https://timesofindia.indiatimes.com/technology/tech-news/this-startup-wants-to-turn-expensive-ola-uber-airport-rides-into-budget-friendly-carpools-heres-how/articleshow/110090721.cms",
  },
  {
    thumbnail: yourStoryThumbnail,
    ans: "YourStory Media on LinkedIn: #cabpooling #startup #GoPool",
    site: "Your Story",
    logo: yourStory,
    link: "https://www.linkedin.com/posts/yourstory-com_carpooling-startup-activity-7196086242919264256-KmW-/",
  },
  {
    thumbnail: curlyTalesThumbnail,
    ans: "What Is GoPool, The App That Lets Users Transform Expensive Airport Commutes Into Budget-Friendly Cabpool?",
    site: "Curly Tales",
    logo: curlyTales,
    link: "https://curlytales.com/what-is-gopool-the-app-that-lets-users-transform-expensive-airport-commutes-into-budget-friendly-carpool/",
  },
  {
    thumbnail: moneyControlThumbnail,
    ans: "What is GoPool, the app that lets users turn expensive airport rides into budget-friendly cabpooling?",
    site: "CNBC",
    logo: cnbc,
    link: "https://www.cnbctv18.com/business/startup/gopool-cheap-ola-uber-carpools-airport-cab-rides-startup-19410762.htm",
  },
];

export const TeamMembers = [
  {
    img: akash,
    Name: "Akash Jadhav",
    Des: "Founder & CEO",
    exp: "10+ years experience as Software Engineer and serial entrepreneur",
    ex1: "Previous Experience",
    ex2: "Unibuddy (SW Eng III) ",
    ex3: "Elucidata (Tech Lead)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/akash-a-jadhav/",
    instagram: "",
  },
  {
    img: sunil,
    Name: "Sunil Dutt",
    Des: "Co-Founder & CMO",
    exp: "Serial entrepreneur - Launched Sweden's first Q-commerce company",
    ex1: "Previous Experience",
    ex2: "Vembla (Founder)",
    ex3: "Telenor (CEO Advisor)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/sunil-dutt-3649821/",
    instagram: "",
  },
  {
    img: nitish,
    Name: "Nitish Purandare",
    Des: "Business Ops",
    exp: "7+ years experience from Mobility sector in India across scaleups",
    ex1: "Previous Experience",
    ex2: "Zoomcar (Sr BM)",
    ex3: "Yulu (1st Ops Hire)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/nitish-purandare-814657154/",
    instagram: "",
  },
  {
    img: chetan,
    Name: "Chetan Patil",
    Des: "Business Ops",
    exp: "3+ years of experience from various industries",
    ex1: "Previous Experience",
    ex2: "Volkswagen India",
    ex3: "(Sr Service Advisor)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/chetanpatil5577/",
    instagram: "",
  },
  {
    img: kush,
    Name: "Agrawal Kush",
    Des: "Frontend Developer",
    exp: "Passionate Software Engineering Intern specializing in Frontend Development with a strong foundation in React.",
    ex1: "Experience",
    ex2: "GoPool (SDE intern)",
    ex3: " ",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/agrawal-kush-9a5184212/",
    instagram: "",
  },
  {
    img: fatima,
    Name: "Fatima Badaoui",
    Des: "Full Stack Developer",
    exp: "Eager and adaptable Full Stack Web Developer fresh from completing a one year training at DCI.",
    ex1: "Experience",
    ex2: "POD E-Commerce Manager",
    ex3: "",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/fatima-badaoui/",
    instagram: "",
  },
];

export const gopoolServices = [
  {
    img: feature1,
    feature: "Identity Verification",
    text: "Secure your profile with Aadhaar-based identity verification for safe and reliable cabpooling",
  },
  {
    img: feature2,
    feature: "Trip Verification",
    text: "Find genuine airport co-travelers by verifying your trip status with your boarding pass and avoid last-minute cancellations",
  },
  {
    img: feature3,
    feature: "LinkedIn Connection",
    text: "Boost credibility and trust by linking your GoPool profile with LinkedIn and earning a verified badge",
  },
  {
    img: feature4,
    feature: "Filter By Gender",
    text: "Choose cabpool matches with the gender filter for women-only rides, enhancing comfort and security",
  },
];


export const imageSlide = [
  {
    col: "primary",
    url: banner2,
    url2: banner6,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
  {
    col: "third",
    url: banner1,
    url2: banner5,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
  {
    col: "primary",
    url: banner3,
    url2: banner7,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
  {
    col: "third",
    url: banner4,
    url2: banner8,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
];

export const webEvents = {
  HOME_PAGE_BUTTON_CLICKED: "HOME_PAGE_BUTTON_CLICKED",
  ARTICLE_PAGE_BUTTON_CLICKED: "ARTICLES_PAGE_BUTTON_CLICKED",
  BLOG_PAGE_BUTTON_CLICKED: "BLOGS_PAGE_BUTTON_CLICKED",
  POLICY_PAGE_BUTTON_CLICKED: "POLICY_PAGE_BUTTON_CLICKED",
  DOWNLOAD_BUTTON_CLICKED: "DOWNLOAD_BUTTON_CLICKED",
  LINKEDIN_ICON_CLICKED: "LINKEDIN_ICON_CLICKED",
  INSTAGRAM_ICON_CLICKED: "INSTAGRAM_ICON_CLICKED",
  FACEBOOK_ICON_CLICKED: "FACEBOOK_ICON_CLICKED",
  FORM_SUBMIT_BUTTON_CLICKED: "FORM_SUBMIT_BUTTON_CLICKED",
  EMAIL_ICON_CLICKED: "EMAIL_ICON_CLICKED",
  CALL_US_BUTTON_CLICKED: "CALL_US_BUTTON_CLICKED",
  WHATSAPP_US_BUTTON_CLICKED: "WHATSAPP_US_BUTTON_CLICKED",
  PLAYSTORE_BUTTON_CLICKED: "PLAYSTORE_BUTTON_CLICKED",
  APPSTORE_BUTTON_CLICKED: "APPSTORE_BUTTON_CLICKED",
  ARTICLE_CLICKED: "ARTICLE_CLICKED",
  DOWNLOAD_PAGE_LANDED: "DOWNLOAD_PAGE_LANDED",
};

export const webEventsCategory = {
  USER: "User",
  NAVIGATION: "Navigation",
};
