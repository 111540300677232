import React from "react";

const ServicesCard = ({ service }) => {
  return (
    <div className="shadow-xl bg-white flex flex-col p-3 my-4 mx-3 rounded-lg hover:scale-105 duration-300">
      <div className="rounded-lg">
        <img className="w-44 mx-auto mt-10" src={service.img} alt="/" />
      </div>
      <div className="text-2xl font-bold text-center py-6 w-50 mt-0 m-auto">
        <h2 className="border-b-2 font-heading border-primary">{service.feature}</h2>
      </div>
      <div className="text-center font-medium">
        <p className="py-2 text-xl mx-8 mt-6">{service.text}</p>
      </div>
    </div>
  );
};

export default ServicesCard;
