import React from "react";
import { AllBlogs } from "../constants";
import BlogsCard from "./BlogsCard";

const Blogs = () => {
  return (
    <div className="comp12 w-full bg-[#e9e8e8] py-14">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0">
        <div className="py-4 text-center">
          <h1 className="py-3 text-4xl font-heading font-medium">
            GoPool Blogs
          </h1>
          <p className="text-[#6D737A] max-w-[800px] mx-auto">
          Explore our latest stories, updates, and featured blog posts from the GoPool community.
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-2 mt-12">
          {AllBlogs.map((Blog) => (
            <BlogsCard Blog={Blog} key={Blog.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
