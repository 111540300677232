import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { BlogContents } from "../constants";

const BlogContent = () => {
  const { slug } = useParams();
  const blog = BlogContents.find((blog, key) => blog.slug === slug);

  return (
    <div className="comp12 w-full bg-white pb-14">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0">
        <div className="mt-10">
          <div className="">
            <h1 className="md:text-5xl text-3xl text-primary font-bold ">
              {blog.title}
            </h1>
            <div className="hidden p-1 mt-5">
              <div className="p-1">
                <img
                  className="h-20 object-cover w-20 p-2 rounded-full"
                  src={blog.img}
                  alt=""
                />
              </div>
              <div className="p-2">
                <p>{blog.name}</p>
                <p>{blog.des}</p>
                <p>{blog.date}</p>
              </div>
            </div>
          </div>
          <div className="mt-14 md:text-lg">
            {blog.intro.map((item, index) => (
              <>
                <p key={index} className="text-justify">
                  {item}
                </p>
                <br key={index} />
              </>
            ))}
            {blog.div.map((division, index) => (
              <div key={index} className="my-4">
                <h3 className="text-2xl font-bold py-4">{division.heading}</h3>
                {division.para.map((item, index) => (
                  <>
                    <p key={index} className="text-justify">
                      {item}
                    </p>
                    <br />
                  </>
                ))}
                {division.head && <p className="mb-2">{division.head}</p>}
                {division.points &&
                  division.points.map((item, index) => (
                    <li className="ml-5" key={index}>
                      {item.type === "text" && item.content}
                      {item.type === "mixed" &&
                        item.content.map((subItem, subIndex) =>
                          subItem.text ? (
                            <span key={subIndex}>{subItem.text}</span>
                          ) : (
                            <a
                              key={subIndex}
                              href={subItem.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              {subItem.link}
                            </a>
                          )
                        )}
                    </li>
                  ))}
                {division.img && (
                  <img
                    className="m-auto py-5 md:h-[450px] h-[300px]"
                    src={division.img}
                    alt=""
                  />
                )}
                {division.footer &&
                  division.footer.map((item, index) => (
                    <>
                      <p key={index}>
                        {item.type === "text" && item.content}
                        {item.type === "mixed" &&
                          item.content.map((subItem, subIndex) =>
                            subItem.text ? (
                              <span key={subIndex}>{subItem.text}</span>
                            ) : (
                              <a
                                key={subIndex}
                                href={subItem.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                              >
                                {subItem.link}
                              </a>
                            )
                          )}
                      </p>
                      <br />
                    </>
                  ))}
              </div>
            ))}
          </div>

          <div>
            {blog.publishedBy && <p className="text-xl mt-6">by</p>}
            {blog.publishedBy && (
              <p className="mb-6 text-xl">{blog.publishedBy}</p>
            )}
            <p className="text-xl mb-5 font-bold">{blog.footerText}</p>
            <Link
              to="https://play.google.com/store/apps/details?id=com.cherryco.gopool.android"
              target="_blank"
            >
              <img
                className="rounded-3xl hover:scale-105 duration-300"
                src={blog.footerBanner}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
